<template>
  <v-card class="app-home rounded-0 h-full position-relative overflow-hidden text-sm">
    <!-- <div class="h-full d-flex align-center justify-center">
      <v-icon size="60">
        {{ menuItemHome.icon }}
      </v-icon>
      <h1 class="pl-2">
        {{ menuItemHome.title }}
      </h1>
    </div> -->

    <v-row class="mt-4">
      <v-col
        cols="12"
        md="3"
        sm="6"
        order="1"
        class="align-self-end"
      >
        <StatisticsCardWithImages
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :change="ratingsOptions.change"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="ratingsOptions.statistics"
          :stat-title="ratingsOptions.statTitle"
          :route="ratingsOptions.route"
        ></StatisticsCardWithImages>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="2"
        class="align-self-end"
      >
        <StatisticsCardWithImages
          :avatar="contactsOptions.avatar"
          :avatar-width="contactsOptions.avatarWidth"
          :change="contactsOptions.change"
          :chip-color="contactsOptions.chipColor"
          :chip-text="contactsOptions.chipText"
          :statistics="contactsOptions.statistics"
          :stat-title="contactsOptions.statTitle"
          :route="contactsOptions.route"
        ></StatisticsCardWithImages>
      </v-col>
      <v-col
        v-if="showPushNotificationCard && userLocal"
        cols="12"
        md="6"
        sm="12"
        order="3"
      >
        <AnalyticsSubscription
          v-if="showPushNotificationCard && userLocal"
          :user="userLocal"
          @close-card="showPushNotificationCard = false"
        />
      </v-col>

      <!-- <v-col
        cols="12"
        md="6"
        sm="12"
        order="5"
        order-md="3"
      >
        <StatisticsCard></StatisticsCard>
      </v-col> -->

      <v-col
        v-for="(stats, index) in statisticsCards"
        :key="index"
        cols="12"
        md="6"
        sm="12"
        order="5"
        order-md="3"
      >
        <StatisticsCard :stats="stats"></StatisticsCard>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMenu } from '@mdi/js'

// Components
import StatisticsCardWithImages from '@/components/Cards/StatisticsCardWithImages.vue'
import StatisticsCard from '@/components/Cards/StatisticsCard.vue'

// Services
import useRouterPath from '@/router/useRouterPath'
import { useAnalyticsModule } from './useAnalyticsModule'
import { useUserNotificationCard } from '../user/useUserNotificationHandler'
import useUser from '@/services/api/modules/user/user'
import AnalyticsSubscription from './AnalyticsSubscription.vue'

export default {
  components: {
    StatisticsCardWithImages,
    StatisticsCard,
    AnalyticsSubscription,
  },
  setup() {
    const isRightSidebarOpen = ref(false)
    const { menuItemHome } = useRouterPath()

    const { showPushNotificationCard } = useUserNotificationCard()

    const userLocal = ref(null)

    // Only call Info user when indicator are TRUE
    if (showPushNotificationCard.value) {
      useUser.fetchUser()
        .then(response => {
          const { user } = response.data
          if (user) {
            userLocal.value = user
          }
        })
    }

    const {
      // Fetch data
      fetchAnalyticsData,
      statisticsCards,
    } = useAnalyticsModule()

    // todo - real all first time
    fetchAnalyticsData()

    // card with images
    const ratingsOptions = {
      statTitle: 'Products',
      statistics: '5',
      change: '+38%',
      chipText: 'Year of 2022',
      chipColor: 'primary',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
      route: undefined,
    }

    const { menuItemContact } = useRouterPath()
    const contactsOptions = {
      statTitle: 'Contacts',
      statistics: '2',
      change: '+50%',
      chipText: 'Last Week',
      chipColor: 'secondary',
      avatar: require('@/assets/images/avatars/10.png'),
      avatarWidth: '86',
      route: menuItemContact?.value?.route,
    }

    return {
      showPushNotificationCard,
      userLocal,
      statisticsCards,

      // Only text
      ratingsOptions,
      contactsOptions,

      menuItemHome,
      name: 'HomePage',
      isRightSidebarOpen,
      icons: {
        mdiMenu,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .content-wrapper {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    min-height: calc(var(--vh, 1vh) * 100);
  }
</style>
