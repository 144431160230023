import {
  mdiArrowDownCircle,
  mdiCheckCircle,
  mdiClose,
} from '@mdi/js'

const STATUS_DRAFT = 'draft'
const STATUS_PUBLISHED = 'published'
const STATUS_FINISHED = 'finished'

const CONTACT_REQUEST_STATUS = {
  DRAFT: STATUS_DRAFT,
  PUBLISHED: STATUS_PUBLISHED,
  FINISHED: STATUS_FINISHED,
}

const contactRequestStatusOptions = [
  {
    value: STATUS_PUBLISHED,
    text: 'Published',
    color: 'success',
    icon: mdiCheckCircle,
  },
  {
    value: STATUS_DRAFT,
    text: 'Draft',
    color: 'warning',
    icon: mdiArrowDownCircle,
  },
  {
    value: STATUS_FINISHED,
    text: 'Finished',
    color: 'error',
    icon: mdiClose,
  },
]

// Translate contactRequestStatus options
// ContactRequest.Fields.status-options.online ==> On line
// ContactRequest.Fields.status-options.busy ==> Busy
// ContactRequest.Fields.status-options.away ==> Away
// ContactRequest.Fields.status-options.offline ==> Off line
// const contactRequestStatusOptions = contactRequestStatusOptions.map(contactRequestStatus => ({ value: contactRequestStatus.value, text: t(`ContactRequest.Fields.status-options.${contactRequestStatus.value}`) }))
// const contactRequestStatusOptions = contactRequestStatusOptions.map(contactRequestStatus => ({ value: contactRequestStatus.value, text: contactRequestStatus.text }))
const resolveContactRequestStatus = status => {
  const result = contactRequestStatusOptions.find(item => item.value === status)
  if (result) {
    return result
  }

  return {
    value: '', text: '', color: '', icon: mdiClose,
  }
}

// Item navegable al estado
const resolveContactRequestRoute = status => {
  const contactRequestStatus = resolveContactRequestStatus(status)

  return {
    id: 'contact-request',
    title: 'ContactRequest',
    value: contactRequestStatus.value,
    text: contactRequestStatus.text,
    icon: contactRequestStatus.icon,
    color: contactRequestStatus.color,
    route: {
      name: 'apps-contact-request-folder',
      params: { folder: contactRequestStatus.value }, // published, draft, finished
      meta: { navActiveLink: 'marketplace' },
    },
    route2: { name: 'apps-contact-request' },
    badge: 0,
  }
}

export {
  CONTACT_REQUEST_STATUS,
  contactRequestStatusOptions,
  resolveContactRequestStatus,
  resolveContactRequestRoute,
}
