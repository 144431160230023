import {
  mdiInformation,
  mdiCircleEditOutline,
  mdiCheckCircle,
  mdiClose,
} from '@mdi/js'

// Estatus de Producto (Supplier)
//  draft:    Creado pero no activo
//  active:   Activo
//  inactive: No activo
// Status
const STATUS_DRAFT = 'draft'
const STATUS_ACTIVE = 'active'
const STATUS_INACTIVE = 'inactive'

const productSupplierStatusOptions = [
  {
    value: STATUS_ACTIVE,
    text: 'Active',
    color: 'success',
    icon: mdiCheckCircle,
  },
  {
    value: STATUS_DRAFT,
    text: 'Draft',
    color: 'warning',
    icon: mdiCircleEditOutline, // mdiArrowDownCircle
  },
  {
    value: STATUS_INACTIVE,
    text: 'Inactive',
    color: 'error',
    icon: mdiInformation,
  },
]

// Translate productSupplierStatus options
// productSupplier.Fields.status-options.online ==> On line
// productSupplier.Fields.status-options.busy ==> Busy
// productSupplier.Fields.status-options.away ==> Away
// productSupplier.Fields.status-options.offline ==> Off line
// const productSupplierStatusOptions = productSupplierStatusOptions.map(productSupplierStatus => ({ value: productSupplierStatus.value, text: t(`productSupplier.Fields.status-options.${productSupplierStatus.value}`) }))
// const productSupplierStatusOptions = productSupplierStatusOptions.map(productSupplierStatus => ({ value: productSupplierStatus.value, text: productSupplierStatus.text }))
const resolveProductSupplierStatus = status => {
  const result = productSupplierStatusOptions.find(item => item.value === status)
  if (result) {
    return result
  }

  return {
    value: '', text: '', color: '', icon: mdiClose,
  }
}

const resolveProductSupplierRoute = status => {
  const productSupplierStatus = resolveProductSupplierStatus(status)

  return {
    id: 'products',
    title: 'Products',
    value: productSupplierStatus.value,
    text: productSupplierStatus.text,
    icon: productSupplierStatus.icon,
    color: productSupplierStatus.color,
    route: {
      name: 'apps-product-supplier-list',
      params: {},
      meta: { navActiveLink: 'marketing' },
    },
    badge: 0,
  }
}

const PRODUCT_SUPPLIER_STATUS = {
  DRAFT: STATUS_DRAFT,
  ACTIVE: STATUS_ACTIVE,
  INACTIVE: STATUS_INACTIVE,
}

export {
  PRODUCT_SUPPLIER_STATUS,
  productSupplierStatusOptions,
  resolveProductSupplierStatus,
  resolveProductSupplierRoute,
}
