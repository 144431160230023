<template>
  <v-alert
    border="left"
    colored-border
    color="error accent-4"
    elevation="2"
  >
    <div class="d-flex">
      <!-- Icon bell: Left -->
      <v-icon size="80">
        {{ icons.mdiBell }}
      </v-icon>

      <!-- Message and button action -->
      <div class="d-flex flex-column ml-4">
        <!-- Puedes activar las Notificaciones Push para recibir en tu dispositivo información en tiempo real sobre ofertas y mensajes. -->
        <h3 class="font-weight-bold font-weight-medium mt-2">Activa las Notificaciones</h3>
        <!-- <v-card-title class="text-base font-weight-semibold">
          Activa las Notificaciones
        </v-card-title> -->
        <span class="mt-2">¡Y no te pierdas nada!</span>
        <v-btn
          class="mt-2"
          outlined
          rounded
          text
          color="error"
          @click="onClickPushNotificationToggle(); $emit('close-card');"
        >
          <span>ACTIVAR NOTIFICACIONES</span>
        </v-btn>
      </div>

      <!-- Close button (Right) -->
      <v-spacer></v-spacer>
      <v-icon
        size="32"
        @click="setPushNotificationCardHide(true)"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
  </v-alert>
</template>

<script>
import { mdiBell, mdiClose } from '@mdi/js'
import { toRefs } from '@vue/composition-api'

// Services
import { useUserNotificationCard, useUserNotificationHandler } from '../user/useUserNotificationHandler'

export default {
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props) {
    const {
      userNotificationLocal,
      onClickPushNotificationToggle,
    } = useUserNotificationHandler(toRefs(props))

    const {
      setPushNotificationCardHide,
    } = useUserNotificationCard()

    return {
      userNotificationLocal,
      onClickPushNotificationToggle,
      setPushNotificationCardHide,

      icons: {
        mdiBell,
        mdiClose,
      },
    }
  },
}
</script>
