import {
  mdiShield,
} from '@mdi/js'

// Notification
import { useToast } from 'vue-toastification/composition'
import { POSITION, TYPE } from 'vue-toastification'
import ToastificationContent from '@/components/ToastificationContent.vue'

// Dependencies
import { resolveCampaignStatus, CAMPAIGN_STATUS } from '@/services/master/CampaignStatus'
import useRouterPath from '@/router/useRouterPath'

export default function useCampaignHandler() {
  // Esto es para traducir las constantes (Textos fijos como Roles, Estados, ...)
  // const { t, te } = useI18nUtils()
  const toast = useToast()

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendCampaignNotification = (title, variant = TYPE.ERROR, icon = mdiShield) => {
    // toast.warning(`Product Supplier ${action}`)
    toast({
      component: ToastificationContent,
      props: {
        title: `Campaign - ${title}`,
        variant,
        icon,
      },
    }, { position: POSITION.BOTTOM_CENTER, type: variant })
  }

  // LABELS
  const updateLabelMenuListItems = [
    { title: 'Personal', value: 'personal', color: 'success' },
    { title: 'Company', value: 'company', color: 'primary' },
    { title: 'Important', value: 'important', color: 'warning' },
    { title: 'Private', value: 'private', color: 'error' },
  ]

  const resolveCampaignLabelColor = label => {
    if (label === 'personal') return 'success'
    if (label === 'company') return 'primary'
    if (label === 'important') return 'warning'
    if (label === 'private') return 'error'

    return 'secondary'
  }

  const {
    menuItemCampaignPublished,
    menuItemCampaignDraft,
    menuItemCampaignFinished,
  } = useRouterPath()

  // CUSTOM ACTIONS
  const customActionPublished = {
    title: menuItemCampaignPublished.text,
    value: menuItemCampaignPublished.value,
    color: menuItemCampaignPublished.color,
    icon: menuItemCampaignPublished.icon,
    route: menuItemCampaignPublished.route,
  }
  const customActionDraft = {
    title: menuItemCampaignDraft.text,
    value: menuItemCampaignDraft.value,
    color: menuItemCampaignDraft.color,
    icon: menuItemCampaignDraft.icon,
    route: menuItemCampaignDraft.route,
  }
  const customActionFinished = {
    title: menuItemCampaignFinished.text,
    value: menuItemCampaignFinished.value,
    color: menuItemCampaignFinished.color,
    icon: menuItemCampaignFinished.icon,
    route: menuItemCampaignFinished.route,
  }

  return {
    sendCampaignNotification,

    // Custom Actions
    customActionPublished,
    customActionDraft,
    customActionFinished,

    // Selected Mail Actions Menu List Item
    updateLabelMenuListItems,

    // UI Campaign
    resolveCampaignLabelColor,
    resolveCampaignStatus,
    CAMPAIGN_STATUS,
  }
}
