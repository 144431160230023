import { mdiBell } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { formatDateToMonthShort } from '@core/utils/filter'

// Notification
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'

// Dependencies
import useUser from '@/services/api/modules/user/user'

// UI Services
// import useAppConfig from '@core/app-config/useAppConfig'
import useAppConfigUser from '@/services/app/useAppConfigUser'

// Dependencies
import useRouterPath from '@/router/useRouterPath'

//
export default function useAnalyticsConverters() {
  // Import locale
  const { useI18n, t } = useI18nUtils()
  const { i18n } = useI18n()
  const updatedAtString = t('global.updatedAt')

  // Updated at 15 Jan
  const getUpdatedAtString = updatedAt => (updatedAt ? `${updatedAtString} ${formatDateToMonthShort(updatedAt, true, i18n.locale)}` : '?')

  // Seller
  const {
    menuItemProductSupplierActive,
    menuItemCampaignPublished,
    menuItemCampaignDraft,
    menuItemCampaignFinished,
    menuItemContactRequestInboundReceived,
    menuItemContactRequestInboundAccepted,
    menuItemContactRequestInboundRejected,
  } = useRouterPath()

  // Purchaser
  const {
    menuItemProductDemander, // (No status)
    menuItemContactRequestPublished,
    menuItemContactRequestDraft,
    menuItemContactRequestFinished,
    menuItemCampaignInboundReceived,
    menuItemCampaignInboundAccepted,
    menuItemCampaignInboundRejected,
  } = useRouterPath()

  // SELLER
  // Marketing view (ProductSupplier(Active) + Campaign(Published))
  const marketingConverter = analytics => ({
    title: 'Marketing',
    updatedAt: getUpdatedAtString(analytics?.updatedAt),
    children: [
      {
        icon: menuItemProductSupplierActive.icon,
        color: menuItemProductSupplierActive.color,
        title: `${menuItemProductSupplierActive.title} (${menuItemProductSupplierActive.text})`,
        subtitle: analytics?.productSupplierStatus?.active ?? '?',
        route: menuItemProductSupplierActive.route,
      },
      {
        icon: menuItemCampaignPublished.icon,
        color: menuItemCampaignPublished.color,
        title: `${menuItemCampaignPublished.title} (${menuItemCampaignPublished.text})`,
        subtitle: analytics?.campaignStatus?.published ?? '?',
        route: menuItemCampaignPublished.route,
      },
    ],
  })

  // Marketing - Campaign(published vs draft vs finished)
  const campaignConverter = analytics => ({
    title: 'Campaign',
    updatedAt: getUpdatedAtString(analytics?.updatedAt),
    children: [
      {
        icon: menuItemCampaignPublished.icon,
        color: menuItemCampaignPublished.color,
        title: menuItemCampaignPublished.text,
        subtitle: analytics?.campaignStatus?.published ?? '?',
        route: menuItemCampaignPublished.route,
      },
      {
        icon: menuItemCampaignDraft.icon,
        color: menuItemCampaignDraft.color,
        title: menuItemCampaignDraft.text,
        subtitle: analytics?.campaignStatus?.draft ?? '?',
        route: menuItemCampaignDraft.route,
      },
      {
        icon: menuItemCampaignFinished.icon,
        color: menuItemCampaignFinished.color,
        title: menuItemCampaignFinished.text,
        subtitle: analytics?.campaignStatus?.finished ?? '?',
        route: menuItemCampaignFinished.route,
      },
    ],
  })

  // Network - Contact Request Inbound (received, accepted, rejected)
  const contactRequestInboundConverter = analytics => ({
    title: 'Network - Inbounds',
    updatedAt: getUpdatedAtString(analytics?.updatedAt),
    children: [
      {
        icon: menuItemContactRequestInboundReceived.icon,
        color: menuItemContactRequestInboundReceived.color,
        title: menuItemContactRequestInboundReceived.text,
        subtitle: analytics?.contactRequestInboundStatus?.received ?? '?',
        route: menuItemContactRequestInboundReceived.route,
      },
      {
        icon: menuItemContactRequestInboundAccepted.icon,
        color: menuItemContactRequestInboundAccepted.color,
        title: menuItemContactRequestInboundAccepted.text,
        subtitle: analytics?.contactRequestInboundStatus?.accepted ?? '?',
        route: menuItemContactRequestInboundAccepted.route,
      },
      {
        icon: menuItemContactRequestInboundRejected.icon,
        color: menuItemContactRequestInboundRejected.color,
        title: menuItemContactRequestInboundRejected.text,
        subtitle: analytics?.contactRequestInboundStatus?.rejected ?? '?',
        route: menuItemContactRequestInboundRejected.route,
      },
    ],
  })

  // PURCHASER
  // Marketplace: 3 elementos
  const marketplaceConverter = analytics => ({
    title: 'Marketplace',
    updatedAt: getUpdatedAtString(analytics?.updatedAt),
    children: [
      {
        icon: menuItemProductDemander.icon,
        color: menuItemProductDemander.color,
        title: `${menuItemProductDemander.title}`,
        subtitle: analytics?.productDemanderStatus?.active ?? '?',
        route: menuItemProductDemander.route,
      },
      {
        icon: menuItemContactRequestPublished.icon,
        color: menuItemContactRequestPublished.color,
        title: `ContactRequest (${menuItemContactRequestPublished.text})`,
        subtitle: analytics?.contactRequestStatus?.published ?? '?',
        route: menuItemContactRequestPublished.route,
      },
    ],
  })

  // Marketplace - ContactRequest(published vs draft vs finished)
  const contactRequestConverter = analytics => ({
    title: 'ContactRequest',
    updatedAt: getUpdatedAtString(analytics?.updatedAt),
    children: [
      {
        icon: menuItemContactRequestPublished.icon,
        color: menuItemContactRequestPublished.color,
        title: menuItemContactRequestPublished.text,
        subtitle: analytics?.contactRequestStatus?.published ?? '?',
        route: menuItemContactRequestPublished.route,
      },
      {
        icon: menuItemContactRequestDraft.icon,
        color: menuItemContactRequestDraft.color,
        title: menuItemContactRequestDraft.text,
        subtitle: analytics?.contactRequestStatus?.draft ?? '?',
        route: menuItemContactRequestDraft.route,
      },
      {
        icon: menuItemContactRequestFinished.icon,
        color: menuItemContactRequestFinished.color,
        title: menuItemContactRequestFinished.text,
        subtitle: analytics?.contactRequestStatus?.finished ?? '?',
        route: menuItemContactRequestFinished.route,
      },
    ],
  })

  // Network - Campaign Inbound (Purchaser)
  const campaignInboundConverter = analytics => ({
    title: 'Network - Campaign Inbounds',
    updatedAt: getUpdatedAtString(analytics?.updatedAt),
    children: [
      {
        icon: menuItemCampaignInboundReceived.icon,
        color: menuItemCampaignInboundReceived.color,
        title: menuItemCampaignInboundReceived.text,
        subtitle: analytics?.campaignInboundStatus?.received ?? '?',
        route: menuItemCampaignInboundReceived.route,
      },
      {
        icon: menuItemCampaignInboundAccepted.icon,
        color: menuItemCampaignInboundAccepted.color,
        title: menuItemCampaignInboundAccepted.text,
        subtitle: analytics?.campaignInboundStatus?.accepted ?? '?',
        route: menuItemCampaignInboundAccepted.route,
      },
      {
        icon: menuItemCampaignInboundRejected.icon,
        color: menuItemCampaignInboundRejected.color,
        title: menuItemCampaignInboundRejected.text,
        subtitle: analytics?.campaignInboundStatus?.rejected ?? '?',
        route: menuItemCampaignInboundRejected.route,
      },
    ],
  })

  return {
    marketingConverter,
    campaignConverter,
    contactRequestInboundConverter,

    marketplaceConverter,
    contactRequestConverter,
    campaignInboundConverter,
  }
}

//
export function useAnalyticsModule() {
  // Notification
  const toast = useToast() // Same interface as this.$toast

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendNotification = (title, variant = TYPE.ERROR, icon = mdiBell) => {
    // toast.warning(`Product Supplier ${action}`)
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `WebPush - ${title}`,
          variant,
          icon,
        },
      },
      { position: POSITION.BOTTOM_CENTER, type: variant },
    )
  }

  // Seller
  const {
    marketingConverter,
    campaignConverter,
    contactRequestInboundConverter,
  } = useAnalyticsConverters()

  // Purchaser
  const {
    marketplaceConverter,
    contactRequestConverter,
    campaignInboundConverter,
  } = useAnalyticsConverters()

  // appMode [seller/purchaser]
  const { isSeller } = useAppConfigUser()

  // Seller
  const marketingStats = ref(null)
  const campaignStats = ref(null)
  const contactRequestInboundStats = ref(null)

  // Purchaser
  const campaignInboundStats = ref(null)
  const contactRequestStats = ref(null)
  const marketplaceStats = ref(null)

  const fillAnalytics = analytics => {
    // Set with analytics data (IF null, SET ?)
    if (isSeller.value) {
      marketingStats.value = marketingConverter(analytics)
      campaignStats.value = campaignConverter(analytics)
      contactRequestInboundStats.value = contactRequestInboundConverter(analytics)
    } else {
      marketplaceStats.value = marketplaceConverter(analytics)
      contactRequestStats.value = contactRequestConverter(analytics)
      campaignInboundStats.value = campaignInboundConverter(analytics)
    }
  }
  const statisticsCards = computed(() => {
    const list = [
      // Seller
      marketingStats.value,
      campaignStats.value,
      contactRequestInboundStats.value,

      // Purchaser
      marketplaceStats.value,
      contactRequestStats.value,
      campaignInboundStats.value,
    ]

    return list.filter(item => item !== null)
  })

  // >>> LocalStorage:
  // Fetch from Local Storage
  const getAnalyticDataFromLocalStorage = () => {
    let analytics = null
    try {
      analytics = JSON.parse(localStorage.getItem('analytics'))
    } catch {
      analytics = null
    }
    fillAnalytics(analytics)
  }

  const setAnalyticDataFromLocalStorage = value => {
    if (value) {
      localStorage.setItem('analytics', JSON.stringify(value))
    } else {
      localStorage.removeItem('analytics')
    }
  }

  // <<< LocalStorage

  // Fetch from API
  const fetchAnalyticsData = () => {
    useUser
      .fetchAnalytics()
      .then(response => {
        const { analytics } = response.data
        if (analytics) {
          fillAnalytics(analytics) // Fill into vars
          setAnalyticDataFromLocalStorage(analytics) // Save on Local Storage
        }
      })
      .catch(error => {
        sendNotification(error.message, 'error')
      })
  }

  // Strategy:
  //    1.- Load values from local storage
  //    2.- On the Component we fetch from API (Async loading)
  //    3.- When profile change (Seller/Purchaser) => Load from Local Storage
  getAnalyticDataFromLocalStorage()

  // Todo watch isSeller:
  //    When directly watching a reactive object, the watcher is automatically in deep mode:
  watch(isSeller, () => {
    // Seller
    marketingStats.value = null
    campaignStats.value = null
    contactRequestInboundStats.value = null

    // Purchaser
    marketplaceStats.value = null
    contactRequestStats.value = null
    campaignInboundStats.value = null

    // Choose option
    // getAnalyticDataFromLocalStorage()
    fetchAnalyticsData()
  })

  return {
    sendNotification,

    // Fetch and all data into array
    fetchAnalyticsData,
    statisticsCards, // All stats into array

    // Seller
    marketingStats,
    campaignStats,
    contactRequestInboundStats,

    // Purchaser
    marketplaceStats,
    contactRequestStats,
    campaignInboundStats,
  }
}
