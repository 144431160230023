import {
  mdiEmailOutline,
  mdiCheckCircle,
  mdiClose,
} from '@mdi/js'

const STATUS_RECEIVED = 'received'
const STATUS_ACCEPTED = 'accepted'
const STATUS_REJECTED = 'rejected'

// Translate UserStatus options
// User.Fields.status-options.online ==> On line
// User.Fields.status-options.busy ==> Busy
// User.Fields.status-options.away ==> Away
// User.Fields.status-options.offline ==> Off line
// const userStatusOptions = contactRequestInboundStatusOptions.map(userStatus => ({ value: userStatus.value, text: t(`User.Fields.status-options.${userStatus.value}`) }))
// const userStatusOptions = contactRequestInboundStatusOptions.map(userStatus => ({ value: userStatus.value, text: userStatus.text }))

const contactRequestInboundStatusOptions = [
  {
    value: STATUS_RECEIVED,
    text: 'Received',
    color: 'warning',
    icon: mdiEmailOutline,
  },
  {
    value: STATUS_ACCEPTED,
    text: 'Accepted',
    color: 'success',
    icon: mdiCheckCircle,
  },
  {
    value: STATUS_REJECTED,
    text: 'Rejected',
    color: 'error',
    icon: mdiClose,
  },
]

const resolveContactRequestInboundStatus = status => {
  const result = contactRequestInboundStatusOptions.find(item => item.value === status)
  if (result) {
    return result
  }

  return {
    value: '', text: '', color: '', icon: mdiClose,
  }
}

// Item navegable al estado
const resolveContactRequestInboundRoute = status => {
  const contactRequestInboundStatus = resolveContactRequestInboundStatus(status)

  return {
    id: 'contact-request-inbound',
    title: 'ContactRequestInbound',
    value: contactRequestInboundStatus.value,
    text: contactRequestInboundStatus.text,
    icon: contactRequestInboundStatus.icon,
    color: contactRequestInboundStatus.color,
    route: {
      name: 'apps-contact-request-inbound-folder',
      params: { folder: contactRequestInboundStatus.value }, // published, draft, finished
      meta: { navActiveLink: 'network' },
    },
    route2: { name: 'apps-contact-request-inbound' },
    badge: 0,
  }
}

const CONTACT_REQUEST_INBOUND_STATUS = {
  RECEIVED: STATUS_RECEIVED,
  ACCEPTED: STATUS_ACCEPTED,
  REJECTED: STATUS_REJECTED,
}

export {
  CONTACT_REQUEST_INBOUND_STATUS,
  contactRequestInboundStatusOptions,
  resolveContactRequestInboundStatus,
  resolveContactRequestInboundRoute,
}
