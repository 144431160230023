<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">{{ stats.title }}</span>

      <v-spacer></v-spacer>

      <v-chip
        small
        color="secondary"
        class="v-chip-light-bg font-weight-semibold secondary--text"
      >
        {{ stats.updatedAt }}
      </v-chip>
    </v-card-title>

    <v-card-subtitle
      v-if="stats.subtitle"
      class="mb-7"
    >
      <span class="font-weight-semibold text--primary me-1">{{ stats.subtitle }}</span>
    </v-card-subtitle>

    <!-- Sustituimos por flex para que ajuste automatico -->
    <!-- <v-card-text>
      <v-row>
        <v-col
          v-for="(child, index) in stats.children"
          :key="index"
          cols="6"
          sm="4"
          class="d-flex align-center"
        >
        </v-col>
      </v-row>
    </v-card-text> -->
    <v-card-text class="d-flex align-center justify-space-between">
      <div
        v-for="(child, index) in stats.children"
        :key="index"
      >
        <!-- <v-list-item
          link
          :to="child.route"
          class="text-decoration-none"
        > -->

        <router-link
          :to="child.route"
          class="text-decoration-none d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="child.color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ child.icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ child.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ child.subtitle }}
            </h3>
          </div>
        </router-link>
        <!-- </v-list-item> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline } from '@mdi/js'

// Services
import useCampaignHandler from '@/views/apps/campaign/campaign/useCampaignHandler'

export default {
  props: {
    stats: {
      type: Object,
      default: () => ({
        title: 'Campaña',
        subtitle: '64% grown',
        updatedAt: 'this month',
        children: [
          {
            title: 'Sales',
            subtitle: '245k',
            icon: mdiTrendingUp,
            color: 'primary',
          },
          {
            title: 'Customers',
            subtitle: '12.5k',
            icon: mdiAccountOutline,
            color: 'success',
          },
          {
            title: 'Product',
            subtitle: '1.54k',
            icon: mdiLabelOutline,
            color: 'warning',
          },
        ],
      }),
    },
    resolveStatisticsIconVariation: {
      type: Function,
      require: true,
      default: data => {
        if (data === 'Sales') return { icon: mdiTrendingUp, color: 'primary' }
        if (data === 'Customers') return { icon: mdiAccountOutline, color: 'success' }
        if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }

        return { icon: mdiAccountOutline, color: 'success' }
      },
    },
  },
  setup() {
    const { resolveCampaignStatus } = useCampaignHandler()

    return {
      resolveCampaignStatus,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
