import {
  mdiContactsOutline, mdiEmail, mdiEmailSend, mdiHome, mdiMessageText, mdiShopping, mdiViewGrid,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

import { PRODUCT_SUPPLIER_STATUS, resolveProductSupplierRoute } from '@/services/master/ProductSupplierStatus'

import { CONTACT_REQUEST_STATUS, resolveContactRequestRoute } from '@/services/master/ContactRequestStatus'
import { CONTACT_REQUEST_INBOUND_STATUS, resolveContactRequestInboundRoute } from '@/services/master/ContactRequestInboundStatus'

// Campaign and CampaignInbound
import { CAMPAIGN_INBOUND_STATUS, resolveCampaignInboundRoute } from '@/services/master/CampaignInboundStatus'
import { CAMPAIGN_STATUS, resolveCampaignRoute } from '@/services/master/CampaignStatus'

// TODO - Translate title
export default function useRouterPath() {
  // Ver AppNavBar.vue
  // const productSupplierFavoritesPendingCount = ref(0)
  const contactRequestsPendingCount = ref(0)
  const contactRequestInboundsPendingCount = ref(0)
  const campaignsPendingCount = ref(2)
  const campaignInboundsPendingCount = ref(1)
  const chatRoomUsersPendingCount = ref(0)

  const menuItemHome = {
    id: 'home',
    title: 'Home',
    icon: mdiHome,
    route: { name: 'home', meta: { navActiveLink: 'home' } },
  }

  const menuItemChat = computed(() => ({
    id: 'chat',
    title: 'Chat',
    icon: mdiMessageText,
    route: { name: 'apps-chat', meta: { navActiveLink: 'chat' } },
    badge: chatRoomUsersPendingCount.value,
  }))
  const menuItemContact = computed(() => ({
    id: 'contact',
    title: 'Contact',
    icon: mdiContactsOutline,
    route: { name: 'apps-contact-list', meta: { navActiveLink: 'chat' } },
    badge: chatRoomUsersPendingCount.value,
  }))

  // >>> Seller
  // ProductSupplier
  const menuItemProductSupplierActive = resolveProductSupplierRoute(PRODUCT_SUPPLIER_STATUS.ACTIVE)
  const menuItemProductSupplierDraft = resolveProductSupplierRoute(PRODUCT_SUPPLIER_STATUS.DRAFT)
  const menuItemProductSupplierInactive = resolveProductSupplierRoute(PRODUCT_SUPPLIER_STATUS.INACTIVE)

  // Campaign
  //  Global: overwrite title, icon and badge
  //  Status: published, draft, finished
  const menuItemCampaign = computed(() => Object.assign(
    resolveCampaignRoute(CAMPAIGN_STATUS.PUBLISHED), // Published default
    {
      title: 'Campaign',
      icon: mdiEmailSend,
      badge: campaignsPendingCount.value,
    },
  ))
  const menuItemCampaignPublished = resolveCampaignRoute(CAMPAIGN_STATUS.PUBLISHED)
  const menuItemCampaignDraft = resolveCampaignRoute(CAMPAIGN_STATUS.DRAFT)
  const menuItemCampaignFinished = resolveCampaignRoute(CAMPAIGN_STATUS.FINISHED)

  // Network (ContactRequestInbound)
  //  Global: overwrite title, icon and badge
  //  Status: received, accepted, rejected
  const menuItemContactRequestInbound = computed(() => Object.assign(
    resolveContactRequestInboundRoute(CONTACT_REQUEST_INBOUND_STATUS.RECEIVED), // Received default
    {
      title: 'Network-S',
      icon: mdiEmailSend,
      badge: contactRequestInboundsPendingCount.value,
    },
  ))
  const menuItemContactRequestInboundReceived = resolveContactRequestInboundRoute(CONTACT_REQUEST_INBOUND_STATUS.RECEIVED)
  const menuItemContactRequestInboundAccepted = resolveContactRequestInboundRoute(CONTACT_REQUEST_INBOUND_STATUS.ACCEPTED)
  const menuItemContactRequestInboundRejected = resolveContactRequestInboundRoute(CONTACT_REQUEST_INBOUND_STATUS.REJECTED)

  //
  // >>> Purchaser
  // Marketplace
  const menuItemProductDemander = {
    id: 'product-demander',
    title: 'Interest List',
    value: '',
    text: '',
    icon: mdiViewGrid,
    color: 'primary',
    route: { name: 'apps-product-demander-list' },
  }
  const menuItemMarketplace = {
    title: 'Marketplace',
    icon: mdiShopping,
    route: { name: 'apps-marketplace-catalog' },
  }

  // ContactRequest
  //  Global: overwrite title, icon and badge
  //  Status: published, draft, finished
  const menuItemContactRequest = computed(() => Object.assign(
    resolveContactRequestRoute(CONTACT_REQUEST_STATUS.PUBLISHED), // Published default
    {
      title: 'ContactRequest',
      icon: mdiEmailSend,
      badge: contactRequestsPendingCount.value,
    },
  ))
  const menuItemContactRequestPublished = resolveContactRequestRoute(CONTACT_REQUEST_STATUS.PUBLISHED)
  const menuItemContactRequestDraft = resolveContactRequestRoute(CONTACT_REQUEST_STATUS.DRAFT)
  const menuItemContactRequestFinished = resolveContactRequestRoute(CONTACT_REQUEST_STATUS.FINISHED)

  // Network (CampaignInbound)
  //  Global: overwrite title, icon and badge
  //  Status: received, accepted, rejected
  const menuItemCampaignInbound = computed(() => Object.assign(
    resolveCampaignInboundRoute(CAMPAIGN_INBOUND_STATUS.RECEIVED), // Received default
    {
      title: 'Network-P',
      icon: mdiEmail,
      badge: campaignInboundsPendingCount.value,
    },
  ))
  const menuItemCampaignInboundReceived = resolveCampaignInboundRoute(CAMPAIGN_INBOUND_STATUS.RECEIVED)
  const menuItemCampaignInboundAccepted = resolveCampaignInboundRoute(CAMPAIGN_INBOUND_STATUS.ACCEPTED)
  const menuItemCampaignInboundRejected = resolveCampaignInboundRoute(CAMPAIGN_INBOUND_STATUS.REJECTED)

  return {
    menuItemHome,

    // Chat-Contact
    menuItemChat,
    menuItemContact,

    // Seller
    // Marketing
    menuItemProductSupplierActive,
    menuItemProductSupplierDraft,
    menuItemProductSupplierInactive,

    // Campaign
    menuItemCampaign, // Menu with badge (ref)
    menuItemCampaignPublished,
    menuItemCampaignDraft,
    menuItemCampaignFinished,

    // ContactRequestInbound (Network)
    menuItemContactRequestInbound, // Menu with badge (ref)
    menuItemContactRequestInboundReceived,
    menuItemContactRequestInboundAccepted,
    menuItemContactRequestInboundRejected,

    // >>> Purchaser
    // Marketplace
    menuItemProductDemander,
    menuItemMarketplace,

    // ContactRequest
    menuItemContactRequest, // Menu with badge (ref)
    menuItemContactRequestPublished,
    menuItemContactRequestDraft,
    menuItemContactRequestFinished,

    // CampaignInbound (Network)
    menuItemCampaignInbound, // Menu with badge (ref)
    menuItemCampaignInboundReceived,
    menuItemCampaignInboundAccepted,
    menuItemCampaignInboundRejected,
  }
}
