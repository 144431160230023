import {
  mdiCircleEditOutline,
  mdiCheckCircle,
  mdiClose,
} from '@mdi/js'

const STATUS_PUBLISHED = 'published'
const STATUS_DRAFT = 'draft'
const STATUS_FINISHED = 'finished'

const campaignStatusOptions = [
  {
    value: STATUS_PUBLISHED,
    text: 'Published',
    color: 'success',
    icon: mdiCheckCircle,
  },
  {
    value: STATUS_DRAFT,
    text: 'Draft',
    color: 'warning',
    icon: mdiCircleEditOutline,
  },
  {
    value: STATUS_FINISHED,
    text: 'Finished',
    color: 'error',
    icon: mdiClose,
  },
]

// Translate CampaignStatus options
// Campaign.Fields.status-options.online ==> On line
// Campaign.Fields.status-options.busy ==> Busy
// Campaign.Fields.status-options.away ==> Away
// Campaign.Fields.status-options.offline ==> Off line
// const campaignStatusOptions = CampaignStatusList.map(CampaignStatus => ({ value: CampaignStatus.value, text: t(`Campaign.Fields.status-options.${CampaignStatus.value}`) }))
// const campaignStatusOptions = CampaignStatusList.map(CampaignStatus => ({ value: CampaignStatus.value, text: CampaignStatus.text }))

const resolveCampaignStatus = status => {
  const result = campaignStatusOptions.find(item => item.value === status)
  if (result) {
    return result
  }

  return {
    value: '', text: '', color: '', icon: mdiClose,
  }
}

const resolveCampaignRoute = status => {
  const campaignStatus = resolveCampaignStatus(status)

  return {
    id: 'campaign',
    title: 'Campaign',
    value: campaignStatus.value,
    text: campaignStatus.text,
    icon: campaignStatus.icon,
    color: campaignStatus.color,
    route: {
      name: 'apps-campaign-folder',
      params: { folder: campaignStatus.value },
      meta: { navActiveLink: 'marketing' },
    },
    route2: { name: 'apps-campaign' },
    badge: 0,
  }
}

const CAMPAIGN_STATUS = {
  DRAFT: STATUS_DRAFT,
  PUBLISHED: STATUS_PUBLISHED,
  FINISHED: STATUS_FINISHED,
}

export {
  CAMPAIGN_STATUS,
  campaignStatusOptions,
  resolveCampaignStatus,
  resolveCampaignRoute,
}
