import {
  mdiEmailOutline,
  mdiCheckCircle,
  mdiClose,
} from '@mdi/js'

const STATUS_RECEIVED = 'received'
const STATUS_ACCEPTED = 'accepted'
const STATUS_REJECTED = 'rejected'

// Translate UserStatus options
// User.Fields.status-options.online ==> On line
// User.Fields.status-options.busy ==> Busy
// User.Fields.status-options.away ==> Away
// User.Fields.status-options.offline ==> Off line
// const userStatusOptions = userStatusList.map(userStatus => ({ value: userStatus.value, text: t(`User.Fields.status-options.${userStatus.value}`) }))
// const userStatusOptions = userStatusList.map(userStatus => ({ value: userStatus.value, text: userStatus.text }))

const campaignInboundStatusOptions = [
  {
    value: STATUS_RECEIVED,
    text: 'Received',
    color: 'warning',
    icon: mdiEmailOutline,
  },
  {
    value: STATUS_ACCEPTED,
    text: 'Accepted',
    color: 'success',
    icon: mdiCheckCircle,
  },
  {
    value: STATUS_REJECTED,
    text: 'Rejected',
    color: 'error',
    icon: mdiClose,
  },
]

const resolveCampaignInboundStatus = status => {
  const result = campaignInboundStatusOptions.find(item => item.value === status)
  if (result) {
    return result
  }

  return {
    value: '', text: '', color: '', icon: mdiClose,
  }
}

// Item navegable al estado
const resolveCampaignInboundRoute = status => {
  const campaignInboundStatus = resolveCampaignInboundStatus(status)

  return {
    id: 'campaign-inbound',
    title: 'CampaignInbound',
    value: campaignInboundStatus.value,
    text: campaignInboundStatus.text,
    icon: campaignInboundStatus.icon,
    color: campaignInboundStatus.color,

    route: {
      name: 'apps-campaign-inbound-folder',
      params: { folder: campaignInboundStatus.value },
      meta: { navActiveLink: 'network' },
    },
    route2: { name: 'apps-campaign-inbound' },
    badge: 0,
  }
}

const CAMPAIGN_INBOUND_STATUS = {
  RECEIVED: STATUS_RECEIVED,
  ACCEPTED: STATUS_ACCEPTED,
  REJECTED: STATUS_REJECTED,
}

// export { campaignInboundStatusList }
export {
  CAMPAIGN_INBOUND_STATUS,
  campaignInboundStatusOptions,
  resolveCampaignInboundStatus,
  resolveCampaignInboundRoute,
}
